<template>
    <div class="pvp">
        <div class="title">
            <h2>Pvp Ranking</h2>
        </div>
        <Loading :active="loading" />
        <div
            class="text-xs text-gray-800 bg-amarelo p-1 mt-2.5 rounded font-bold py-3 mb-2 border border-solid border-roxo z-2">
            <i class="fa fa-info-circle"></i>
            {{ $t('pvp.nota') }} - {{ params.date | formatDate }}
        </div>
        <select class="pvp-select" v-model="type">
            <option v-for="(t, k) in typesNew" :key="k" :value="t.id">{{
                t.text
            }}</option>
        </select>
        <input class="pvp-input" type="text" v-model="text" :placeholder="$t('missao.pesquisar')" />
        <div class="type-pokemon">
            <div class="type-select" v-for="(t, k) in typesPokemon" :key="k" @click.prevent="setType(t, k)"
                :class="{ 'active': activeType(t) }">
                <div class="pok">
                    <img :src="getImgUrl(t)" />
                </div>
            </div>
        </div>
        <div v-for="(d, k) in dadosNew" :key="k" class="pvp-container">
            <div class="number" :class="d.type[0]"></div>
            <div class="pvp-content" :class="`${d.type[0]}--gradient`">
                <div class="cont-img">
                    <img class="pvp-img" @error="$event.target.src = require('../assets/pvp/1.png')" :src="getImg(d.dex, d.dados.tags, d.speciesId, d)
                        " />
                    <div class="number-rank">{{ d.pos }}º</div>
                </div>
                <div class="text">
                    <div class="text-x">
                        <div class="text--content">
                            <div class="dex">#{{ d.dex }}</div>
                            <div class="name">
                                {{ d.speciesName }}
                                <!-- <div class="xl">XL</div> -->
                            </div>
                        </div>
                        <div class="moves">
                            <div v-for="(m, km) in d.movesets" :key="km">
                                <div class="tags" :class="`tag-${m.golpe.type}`">
                                    {{ m.golpe.name }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="rank">
                    <div class="score">{{ d.score }}</div>
                    <div class="score-type">
                        <div v-for="(t, tk) in d.type" :key="tk">
                            <img :src="getImgUrl(t)" v-if="t !== 'none'" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <vue-ads-pagination :total-items="total" :max-visible-pages="3" :page="page" :items-per-page="perpage"
            @page-change="pageChange">
            <template slot-scope="props" v-if="true">
                <div class="vue-ads-pr-2 vue-ads-leading-loose">
                    {{ props.start }}
                </div>
            </template>
            <template slot="buttons" slot-scope="props">
                <vue-ads-page-button v-for="(button, key) in props.buttons" :key="key" v-bind="button"
                    @page-change="pageChange(button)" />
            </template>
        </vue-ads-pagination>
    </div>
</template>

<script>
import Loading from '../components/Loading'
import axios from 'axios'
import game from './data/gamemaster.min.json'
import game_es from './data/gamemaster_es.min.json'
import { getAnalytics, logEvent } from 'firebase/analytics'
import moment from 'moment'
import 'vue-ads-pagination/dist/vue-ads-pagination.css'
import VueAdsPagination, { VueAdsPageButton } from 'vue-ads-pagination'
import { calculate } from '../utils/pokemon'
const url = 'https://images.weserv.nl/?dpr=1&w=200&il&url=https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Types/'
//{{ d.dados.tags }}
export default {
    components: {
        Loading,
        VueAdsPagination,
        VueAdsPageButton,
    },
    filters: {
        formatDate(x) {
            return moment(x).format('DD/MM/YYYY')
        },
    },
    computed: {
        typesNew() {
            return this.$i18n.locale === 'pt-br'
                ? this.types_pt
                : this.$i18n.locale === 'es-es'
                    ? this.types_es
                    : this.types
        },
        dadosNew() {
            if (this.text || this.select.length) {
                return this.dados.filter(p => {
                    if (this.text && this.select.length) {
                        return (
                            p.speciesName
                                .toLowerCase()
                                .normalize('NFD')
                                .replace(/[\u0300-\u036f]/g, '')
                                .includes(
                                    this.text
                                        .toLowerCase()
                                        .normalize('NFD')
                                        .replace(/[\u0300-\u036f]/g, '')
                                ) && p.type.filter(i => this.select.includes(i)).length
                        )
                    }
                    if (this.text) {
                        return p.speciesName
                            .toLowerCase()
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .includes(
                                this.text
                                    .toLowerCase()
                                    .normalize('NFD')
                                    .replace(/[\u0300-\u036f]/g, '')
                            )
                    }
                    return p.type.filter(i => this.select.includes(i)).length
                })
            }
            return this.dados
        }
    },
    data: () => ({
        dados: [],
        dadosRes: [],
        gameRes: [],
        type: 1,
        page: 0,
        total: 0,
        perpage: 100,
        types: [
            { id: 1, text: 'Great League' },
            { id: 2, text: 'Ultra League' },
            { id: 3, text: 'Master League' }
        ],
        types_pt: [
            { id: 1, text: 'Grande Liga' },
            { id: 2, text: 'Ultra Liga' },
            { id: 3, text: 'Liga Mestra' }
        ],
        types_es: [
            { id: 1, text: 'Liga Super Ball' },
            { id: 2, text: 'Liga Ultra Ball' },
            { id: 3, text: 'Liga Master Ball' }
        ],
        typesPokemon: [
            'bug',
            'dark',
            'dragon',
            'electric',
            'fairy',
            'fighting',
            'fire',
            'flying',
            'ghost',
            'grass',
            'ground',
            'ice',
            'normal',
            'poison',
            'psychic',
            'rock',
            'steel',
            'water'
        ],
        select: [],
        text: '',
        loading: false,
        params: {}
    }),
    watch: {
        type() {
            this.getDados(1)
        }
    },
    methods: {
        activeType(value) {
            return this.select.filter(i => i === value).length
        },
        setType(value) {
            if (!this.select.length) {
                return this.select.push(value)
            }
            const index = this.select.findIndex(i => i === value)
            if (index === -1) {
                return this.select.push(value)
            }
            return this.select.splice(index, 1)
        },
        getKeyByValue(object, value) {
            return Object.keys(object).find(key => object[key] === value)
        },
        async mapDados() {
            this.dados = []
            let gameNew =
                this.$i18n.locale === 'pt-br'
                    ? this.gameRes
                    : this.$i18n.locale === 'es-es'
                        ? game_es
                        : game
            const dados = this.dadosRes
            const newDados = await dados.map(i => {
                const pokemon = gameNew.pokemon.filter(
                    x => x.speciesId === i.speciesId
                )[0]
                i.dados = pokemon
                i.dex = pokemon?.dex ? pokemon.dex : null
                let evoRanks = calculate(i.dados.baseStats.atk, i.dados.baseStats.def, i.dados.baseStats.hp, '1', '15', '51', false, this.type === 1 ? 1500 : this.type === 2 ? 2500 : 10000, i.dados.speciesName)
                //var evoKeys = Object.keys(evoRanks)
                //var evoMaxStatProd = evoKeys[0]
                //var evoMaxStatProd = evoKeys[0].split(".")[0];
                i.rankPerf = evoRanks
                i.minLvl = evoRanks.minLvl
                i.maxLvl = evoRanks.maxLvl
                i.type = pokemon.types
                i.movesets = i.moveset.map(m => {
                    return {
                        golpe: gameNew.moves.filter(x => x.moveId === m)[0]
                    }
                })
                return i
            })
            /* this.dados = newDados.map((i, k) => {
                i.pos = k + 1
                return i
            }) */
            console.log(newDados)
            this.dados = newDados
            this.loading = false
        },
        pageChange(page) {
            this.dados = []
            this.page = page.page
            this.getDados(page.page + 1)
            document.body.scrollIntoView({ behavior: 'smooth', block: 'start' })
        },
        async getDados(page) {
            this.loading = true
            this.dadosRes = []
            const check = await axios(`${this.$url}/tt/date`).catch(() => this.loading = false)
            this.params = {
                date: check.data.date,
                version: check.data.version
            }
            const dadosRes = await axios.get(`${this.$url}/tt/rank`, {
                params: {
                    version: this.params.version,
                    value: this.type === 1 ? 1500 : this.type === 2 ? 2500 : 10000,
                    page: page
                }
            }).catch(() => this.loading = false)
            this.total = dadosRes.data.total
            this.dadosRes = Array.isArray(dadosRes.data.data) ? dadosRes.data.data.map((i, k) => {
                i.pos = Number(k + 1)
                return i
            }) : Object.entries(dadosRes.data.data).map(i => {
                i[1].pos = Number(i[0]) + 1
                return i[1]
            })
            //console.log(this.total, this.dadosRes)
            const gameRes = await axios(`${this.$url}/tt/game`, {
                params: {
                    version: this.params.version,
                    lang: this.$i18n.locale === 'pt-br'
                        ? `pt`
                        : this.$i18n.locale === 'es-es'
                            ? `es`
                            : `en`
                }
            })
            this.gameRes = gameRes.data
            console.log(this.gameRes)
            if (this.dadosRes.length) {
                this.mapDados()
            }
        },
        getImgUrl(img) {
            return `${url}POKEMON_TYPE_${img.toUpperCase()}.png`
        },
        getImg(dex, option, name) {
            //console.log(name, item.pos)
            let optionNew
            if (name.match(/alola/)) {
                optionNew = '.fALOLA'
            } else if (name.match(/galarian/)) {
                optionNew = '.fGALARIAN'
            } else if (name.match(/midnight/)) {
                optionNew = '.fMIDNIGHT'
            } else if (name.match(/midday/)) {
                optionNew = '.fMIDDAY'
            } else if (name.match(/pom_pom/)) {
                optionNew = '.fPOMPOM'
            } else if (name.match(/sensu/)) {
                optionNew = '.fSENSU'
            } else if (name.match(/baile/)) {
                optionNew = '.fBAILE'
            } else if (name.match(/pau/)) {
                optionNew = '.fPAU'
            } else if (name.match(/trash/)) {
                optionNew = '.fWORMADAM_TRASH'
            } else if (name.match(/plant/)) {
                optionNew = '.fWORMADAM_PLANT'
            } else if (name.match(/sandy/)) {
                optionNew = '.fWORMADAM_SANDY'
            } else if (name.match(/altered/)) {
                optionNew = '.fALTERED'
            } else if (name.match(/origin/)) {
                optionNew = '.fORIGIN'
            } else if (name === 'genesect') {
                optionNew = '.fNORMAL'
            } else if (name === 'kyurem') {
                optionNew = '.fNORMAL'
            } else if (name === 'gastrodon') {
                optionNew = '.fWEST_SEA'
            } else if (name === 'florges') {
                optionNew = '.fWHITE'
            } else if (name === 'spinda') {
                optionNew = '.f00'
            } else {
                optionNew = ''
            }

            //console.log(optionNew)
            return `https://images.weserv.nl/?dpr=1&w=200&il&url=https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Pokemon%20-%20256x256/Addressable%20Assets/pm${dex}${optionNew}.icon.png`
        }
    },
    mounted() {
        const analytics = getAnalytics(this.$fire)
        logEvent(analytics, 'screen_view', {
            firebase_screen: `APP - ${this.$route.meta.lang} ${this.$route.meta.titulo}`,
            firebase_screen_class: 'PVP'
        })
        document.title = `${this.$route.meta.lang} CoordsGo.com - ${this.$route.meta.titulo}`
        this.getDados(1)
    }
}
</script>

<style lang="scss">
input.pvp-input {
    margin: 10px 0 !important;
    //height: 20px !important;
    padding: 12px 14px !important;
    background-color: #fff !important;
    border-radius: 4px !important;
    width: 100% !important;
    border: 1px solid #ccc !important;
}
</style>

<style lang="scss" scoped>
.title {
    h2 {
        display: flex;
        justify-content: space-between;
        color: #673bb7;
        margin: 10px 0;
        font-size: 23px;
        border-bottom: 1px solid #673bb7;
        font-weight: bold;
    }
}

.pvp-container {
    border: 1px solid #ccc;
    margin: 10px 0;
    border-radius: 10px;

    .pvp-content {
        padding: 0 10px 10px 10px;
        display: flex;

        .pvp-img {
            width: 80px;
        }

        .text--content {
            display: flex;

            .dex {
                font-size: 16px;
                margin-right: 5px;
                color: #fff;
                text-shadow: 0 0 3px rgb(0 0 0 / 50%);
            }

            .name {
                font-size: 16px;
                font-weight: bold;
                color: #fff;
                text-shadow: 0 0 3px rgb(0 0 0 / 50%);
            }
        }

        .rank {
            width: 75px;
            display: flex;
            flex: 1;
            flex-direction: column;
        }
    }

    .cont-img {
        width: 100px;
        position: relative;
    }
}

.score-type {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: flex-end;

    img {
        margin-right: 5px;
        height: 20px;
    }
}

.number-rank {
    font-weight: bolder;
    color: #fff;
    font-size: 26px;
    position: absolute;
    bottom: 80%;
    text-align: left;
    width: 80px;
}

.number {
    padding: 10px;
    font-size: 14px;
    font-weight: bold;
}

.text {
    width: 100%;
    text-align: left;
}

.xl {
    display: inline-block;
    margin-left: 15px;
    background: #000;
    border-radius: 4px;
    padding: 2px 6px;
    color: #fff;
    opacity: 0.3;
    font-size: 12px;
    position: relative;
    top: -1px;
}

.moves {
    display: flex;
    flex-wrap: wrap;
}

.tags {
    border-radius: 10px;
    padding: 3px 5px;
    font-size: 10px;
    margin-right: 5px;
    margin-top: 5px;
    text-shadow: 0 0 3px rgb(0 0 0 / 50%);
    box-shadow: 0 0 3px rgb(255 255 255 / 70%);
}

.score {
    padding: 5px;
    width: 42px;
    text-align: center;
    font-weight: bold;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 8px;
    color: #fff;
    margin-bottom: 10px;
}

$types: (
    'bug' #9ec52c #000,
    'dark' #5b5666 #fff,
    'dragon' #046ebe #fff,
    'electric' #f8d84c #fff,
    'fairy' #ea93de #000,
    'fighting' #d64061 #fff,
    'fire' #ff9f49 #000,
    'flying' #ff9f49 #000,
    'ghost' #656bc2 #fff,
    'grass' #5cbf69 #000,
    'ground' #d3804a #fff,
    'ice' #7cd2c7 #000,
    'normal' #8f99a1 #fff,
    'poison' #a267c8 #fff,
    'psychic' #f76e71 #fff,
    'rock' #cdbc89 #fff,
    'steel' #4f8ba0 #fff,
    'water' #60a6dc #fff
);

@each $class,
$background,
$color in $types {
    .#{$class} {
        background-color: $background;
        color: $color;
        border-radius: 10px 10px 0 0;
    }
}

@each $class,
$background,
$color in $types {
    .tag-#{$class} {
        background-color: $background;
        color: #fff;
    }
}

@mixin gradient($direction, $color1, $color2) {
    border-radius: 0 0 10px 10px;
    background: linear-gradient($direction, $color1, $color2);
    background: -moz-linear-gradient($direction, $color1, $color2);
    background: -o-linear-gradient($direction, $color1, $color2);
    background: -webkit-linear-gradient($direction, $color1, $color2);
}

@each $class,
$color1 in $types {
    .#{$class}--gradient {
        @include gradient(270deg, $color1, #fff);
    }
}

.pvp-select {
    margin: 10px 0;
    height: 43px;
    padding: 12px 14px;
    background-color: #fff;
    border-radius: 4px;
    width: 100%;
    border: 1px solid #ccc;
}

.nota1 {
    font-size: 12px;
    color: #2c3e50;
    border: 1px solid #673bb7;
    background: #ffc107;
    padding: 5px;
    border-radius: 5px;
    font-weight: bold;
    min-height: 48px;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}


.type-pokemon {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-auto-flow: row;
    gap: 3px;
    margin-top: 10px;

    .type-select {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .pok {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35px;
        height: 35px;
    }
}

.text-x {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.event {
    content: '50%';
    position: absolute;
    width: 42px;
    right: 55px;
}

.type-select {
    border: 2px solid transparent;
    border-radius: 50%;
    margin: 5px;
}



.active {
    border-color: #ccc;
}

.vue-ads-leading-loose {
    display: none !important;
}

.vue-ads-justify-end {
    justify-content: flex-start !important;
}

.vue-ads-bg-teal-500 {
    background-color: #673bb7 !important;
}

.vue-ads-flex {
    width: 100% !important;
    box-sizing: border-box !important;
    padding: 0 !important;
    margin: 10px 0 !important;
}

.vue-ads-flex-grow {
    flex-grow: 0 !important;
    flex: 1 !important;
    justify-content: space-between !important;
}

.vue-ads-text-xs {
    font-size: 1rem !important;
}
</style>